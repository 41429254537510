import { createApp } from "vue";
import { createPinia } from "pinia";
import App from "./App.vue";
import router from "./router";
import ElementPlus from "element-plus";
import "element-plus/dist/index.css";
import * as ElementPlusIconsVue from "@element-plus/icons-vue";
import "@/registerServiceWorker";
import { role } from "@/directives";
import "element-plus/theme-chalk/el-reset.css";
import "element-plus/theme-chalk/dark/css-vars.css";
import "@/assets/styles/main.styl";
import "@/assets/styles/theme-vars.styl";
import ru from "element-plus/dist/locale/ru.mjs";

const app = createApp(App);
const pinia = createPinia();
app.use(ElementPlus, {
  locale: ru,
});
app.directive("role", role);
app.use(pinia).use(router);

// Регистрируем иконки Element Plus
for (const [key, component] of Object.entries(ElementPlusIconsVue)) {
  app.component(key, component);
}

app.mount("#app");
