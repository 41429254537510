import type { NavigationGuardNext, RouteLocationNormalized } from "vue-router";
import { useAuthStore } from "@/stores/modules/auth";
import { ROUTE_NAMES } from "@/constants/routes";
import { setMetaData } from "@/utils/meta";
import { RouteMeta } from "@/router/types";

/**
 * Guard для проверки авторизации и прав доступа
 */
export async function authGuard(
  to: RouteLocationNormalized,
  from: RouteLocationNormalized,
  next: NavigationGuardNext
) {
  const auth = useAuthStore();
  const meta = to.meta as RouteMeta;

  try {
    // Инициализация авторизации, если необходимо
    if (!auth.getInitialized) {
      await auth.init();
    }

    // Установка мета-данных страницы
    if (meta.title) {
      const title =
        typeof meta.title === "function" ? meta.title(to) : meta.title;
      setMetaData(
        title,
        meta.description ||
          "SMMAI - это платформа для создания и управления ИИ агентами"
      );
    }

    // Проверка авторизации
    if (!meta.public && !auth.isAuthenticated) {
      auth.logout();
      auth.returnUrl = to.fullPath;
      return next({ name: ROUTE_NAMES.LOGIN });
    }

    // Проверка прав доступа
    if (auth.isAuthenticated && meta.requiresAuth) {
      const hasAccess = await checkAccess(auth, meta);
      if (!hasAccess) {
        return next({ name: ROUTE_NAMES.UNAUTHORIZED });
      }
    }

    // Редирект с логина при авторизации
    if (auth.isAuthenticated && to.name === ROUTE_NAMES.LOGIN) {
      return next({ name: ROUTE_NAMES.HOME });
    }

    next();
  } catch (error) {
    console.error("Auth guard error:", error);
    next({ name: ROUTE_NAMES.NOT_FOUND });
  }
}

/**
 * Проверка прав доступа к маршруту
 */
async function checkAccess(
  auth: ReturnType<typeof useAuthStore>,
  meta: RouteMeta
): Promise<boolean> {
  const { roles, permissions, accessMode = "any" } = meta;

  // Если нет ограничений - разрешаем
  if (!roles && !permissions) {
    return true;
  }

  // Проверка ролей
  if (roles) {
    const hasRole =
      accessMode === "all" ? auth.hasAllRoles(roles) : auth.hasAnyRole(roles);

    if (!hasRole) return false;
  }

  // Проверка разрешений
  if (permissions) {
    const hasPermission =
      accessMode === "all"
        ? permissions.every((p) => auth.hasPermission(p))
        : permissions.some((p) => auth.hasPermission(p));

    if (!hasPermission) return false;
  }

  return true;
}
