import { ref, onMounted, onUnmounted } from "vue";

export function useResponsive(breakpoint = 768) {
  const isMobileView = ref(false);

  const checkView = () => {
    isMobileView.value = window.innerWidth < breakpoint;
  };

  onMounted(() => {
    checkView();
    window.addEventListener("resize", checkView);
  });

  onUnmounted(() => {
    window.removeEventListener("resize", checkView);
  });

  return {
    isMobileView,
  };
}
