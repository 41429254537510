<template>
  <svg
    width="64"
    height="64"
    viewBox="0 0 64 64"
    xmlns="http://www.w3.org/2000/svg"
  >
    <!-- Внешняя светлая обводка (эффект свечения) -->
    <path
      d="M32 6 L58 56 L42 56 L36 40 L28 40 L22 56 L6 56 L32 6Z"
      fill="none"
      stroke="var(--logo-light)"
      stroke-width="3"
      stroke-linejoin="round"
      opacity="0.5"
    />

    <!-- Основная форма (динамичная A) с темной обводкой -->
    <path
      d="M32 6 L58 56 L42 56 L36 40 L28 40 L22 56 L6 56 L32 6Z"
      fill="var(--logo-primary)"
      stroke="var(--logo-dark)"
      stroke-width="2"
      stroke-linejoin="round"
    />

    <!-- Внутренние детали с обводкой -->
    <path
      d="M32 16 L44 42 L32 36 L20 42 L32 16Z"
      fill="var(--logo-medium)"
      stroke="var(--logo-dark)"
      stroke-width="1"
      stroke-linejoin="round"
    />

    <!-- Вертикальные линии внутри треугольника (сужающиеся кверху) -->
    <!-- Левая линия -->
    <path
      d="M29 24 L30 34"
      stroke="var(--logo-dark)"
      stroke-width="3"
      stroke-linecap="round"
    />
    <path
      d="M29 24 L30 34"
      stroke="var(--logo-light)"
      stroke-width="1"
      stroke-linecap="round"
      opacity="0.5"
    />

    <!-- Правая линия -->
    <path
      d="M35 24 L34 34"
      stroke="var(--logo-dark)"
      stroke-width="3"
      stroke-linecap="round"
    />
    <path
      d="M35 24 L34 34"
      stroke="var(--logo-light)"
      stroke-width="1"
      stroke-linecap="round"
      opacity="0.5"
    />

    <!-- Динамичные линии -->
    <path
      d="M32 20 L38 35 M32 20 L26 35"
      stroke="var(--logo-medium)"
      stroke-width="4"
      stroke-linecap="round"
      fill="none"
    />

    <!-- Дополнительная внутренняя обводка для глубины -->
    <path
      d="M32 16 L44 42 L32 36 L20 42 L32 16Z"
      fill="none"
      stroke="var(--logo-dark)"
      stroke-width="0.5"
      stroke-linejoin="round"
      opacity="0.6"
    />
  </svg>
</template>
