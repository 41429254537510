import type { RouteRecordRaw } from "vue-router";
import { ROUTE_NAMES } from "@/constants/routes";
import { lazyLoad } from "../../utils/lazyLoad";
export const surveyRoutes: RouteRecordRaw[] = [
  {
    path: "/survey",
    meta: {
      requiresAuth: true,
      icon: "List",
      hideInMenu: false,
    },
    children: [
      {
        path: "/survey",
        name: ROUTE_NAMES.SURVEY,
        component: lazyLoad("survey/SurveyPage"),
        meta: {
          requiresAuth: true,
          title: "Анкеты",
          breadcrumb: "Анкеты",
          hideInMenu: false,
        },
      },
    ],
  },
];
