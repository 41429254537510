export const ROUTE_NAMES = {
  THEME: "theme",
  HOME: "home",
  LOGIN: "login",
  SIGNUP: "signup",
  BOTS: "bots",
  ADMIN_BOTS: "admin-bots",
  BROADCAST_BOT: "broadcast-bot",
  BOT: "bot",
  BOT_ITEM: "bot-item",
  CONTENTS: "contents",
  BROADCAST_CONTENT: "broadcast-content",
  SETTINGS: "settings",
  MESSAGES: "messages",
  FILES: "files",
  UNAUTHORIZED: "unauthorized",
  USERS: "users",
  PROFILE: "profile",
  NOT_FOUND: "not-found",
  ABOUT: "about",
  CONTENT_ITEM: "content-item",
  MEDIA: "media",
  FUNNELS: "funnels",
  COMMANDS: "commands",
  FUNNEL_EDITOR: "funnel-editor",
  INSTRUCTIONS: "instructions",
  TASK_ITEM: "task-item",
  TASK_LIST: "task-list",
  SUBSCRIBERS: "subscribers",
  FUNNEL_STATS: "funnel-stats",
  AI: "ai",
  AI_KNOWLEDGE: "ai-knowledge",
  AI_CHAT: "ai-chat",
  USER_PERMISSIONS: "user-permissions",
  PERMISSIONS: "permissions",
  PLAN: "plan",
  SUBSCRIPTION: "subscription",
  ADMIN: "admin",
  SURVEY: "survey",
  REDIS: "redis",
  MENU: "menu",
  FLOW_MENU: "flow-menu",
  MENU_EDITOR: "menu-editor",
  TAGS: "tags",
  OFFERS: "offers",
  PAYMENTS: "payments",
  YOOKASSA_SETTINGS: "yookassa-settings",
} as const;
