import type { RouteRecordRaw } from "vue-router";
import { ROUTE_NAMES } from "@/constants/routes";
import { lazyLoad } from "../../utils/lazyLoad";
export const aiRoutes: RouteRecordRaw[] = [
  {
    path: "/ai",
    component: lazyLoad("ai/AiAgent"),
    meta: {
      requiresAuth: true,
      title: "AI",
      breadcrumb: "AI",
      icon: "Avatar",
      hideInMenu: false,
      roles: ["admin", "user"],
    },
  },
  {
    path: "/ai/:aiId/knowledge",
    name: ROUTE_NAMES.AI_KNOWLEDGE,
    component: lazyLoad("ai/AgentKnowledge"),
    props: true,
    meta: {
      title: "База знаний агента",
      breadcrumb: "База знаний агента",
      hideInMenu: true,
      requiresAuth: true,
    },
  },
  {
    path: "/ai/:aiId/chat",
    name: ROUTE_NAMES.AI_CHAT,
    component: lazyLoad("ai/AgentChat"),
    props: true,
    meta: {
      requiresAuth: true,
      title: "Чат с агентом",
      breadcrumb: "Чат с агентом",
      hideInMenu: true,
    },
  },
];
