import { useAuthStore } from "@/stores/modules/auth";

export const fetchWrapper = {
  get: request("GET"),
  post: request("POST"),
  put: request("PUT"),
  delete: request("DELETE"),
  patch: request("PATCH"),
};

function request(method: string) {
  return async (url: string, body?: unknown) => {
    const requestOptions: RequestInit = {
      method,
      headers: authHeader(url),
    };
    if (body) {
      requestOptions.headers = {
        ...requestOptions.headers,
        "Content-Type": "application/json",
      };
      requestOptions.body = JSON.stringify(body);
    }

    try {
      const response = await fetch(url, requestOptions);
      const result = await handleResponse(response);
      return result;
    } catch (error) {
      return Promise.reject(error);
    }
  };
}

function authHeader(url: string): HeadersInit {
  const { accessToken } = useAuthStore();
  const API_URL = process.env.VUE_APP_API_URL;
  if (!API_URL) {
    throw new Error("API_URL is not defined");
  }
  const isApiUrl = url.startsWith(API_URL);

  if (accessToken && isApiUrl) {
    return { Authorization: `Bearer ${accessToken}` };
  }
  return {};
}

async function handleResponse(response: Response) {
  let responseData: any = null;

  try {
    const contentType = response.headers.get("content-type");

    // Парсим ответ только один раз
    if (contentType && contentType.includes("application/json")) {
      const text = await response.text();
      try {
        responseData = JSON.parse(text);
      } catch (e) {
        console.error("JSON parse error:", e);
        throw new Error("INVALID_JSON_RESPONSE");
      }
    } else {
      responseData = await response.text();
    }

    // Стандартизируем успешный ответ
    if (response.ok) {
      return {
        success: true,
        data: responseData.data || responseData,
        message: responseData.message || "Success",
        meta: responseData.meta || null,
      };
    }

    // Обработка ошибок авторизации
    if (
      [401].includes(response.status) &&
      !response.url?.includes("/auth/logout")
    ) {
      console.warn(
        `Authentication error: ${response.status} - ${response.url}`
      );

      // Устанавливаем флаг в авторизационном сторе вместо вызова logout
      const authStore = useAuthStore();
      authStore.setAuthenticationError(true);

      return Promise.reject({
        code: "AUTH_ERROR",
        status: response.status,
        success: false,
        error: responseData?.message || "Authentication failed",
        data: null,
      });
    }

    // Категоризация ошибок по HTTP-статусам
    const errorCategories = {
      400: "BAD_REQUEST",
      404: "NOT_FOUND",
      409: "CONFLICT",
      422: "VALIDATION_ERROR",
      500: "SERVER_ERROR",
    };

    const errorCode =
      errorCategories[response.status as keyof typeof errorCategories] ||
      responseData?.code ||
      `HTTP_ERROR_${response.status}`;

    // Извлекаем детализированную информацию об ошибке
    const errorDetails =
      responseData?.errors ||
      responseData?.validation ||
      responseData?.details ||
      null;

    // Стандартизируем ответ с ошибкой
    return Promise.reject({
      code: errorCode,
      status: response.status,
      success: false,
      error:
        responseData?.message ||
        responseData?.error ||
        response.statusText ||
        "Unknown error",
      data: responseData?.data || null,
      details: errorDetails,
    });
  } catch (error: any) {
    // Обработка ошибок на уровне запроса/парсинга
    const isNetworkError =
      error.name === "TypeError" && error.message.includes("fetch");
    const errorCode = isNetworkError
      ? "NETWORK_ERROR"
      : "REQUEST_PROCESSING_ERROR";

    console.error(`Fetch wrapper error [${errorCode}]:`, {
      url: response.url,
      originalError: error.message,
      stack: error.stack,
    });

    return Promise.reject({
      code: errorCode,
      success: false,
      error: isNetworkError
        ? "Network connection error"
        : "Failed to process response",
      originalError: error.message,
    });
  }
}
