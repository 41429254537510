import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createElementBlock as _createElementBlock, renderList as _renderList, Fragment as _Fragment, resolveDynamicComponent as _resolveDynamicComponent, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "side-nav-toggle"
}

import { computed, ref } from "vue";
import { RouteRecordRaw, useRouter } from "vue-router";
import { useAuthStore } from "@/stores/modules/auth";
import { useResponsive } from "@/composables/useResponsive";
import {
  HomeFilled,
  User,
  Document,
  InfoFilled,
  Brush,
  Message,
  Menu,
  Close,
  Avatar,
  Key,
  Ticket,
  CreditCard,
  Guide,
  Odometer,
  List,
  CollectionTag,
  Money,
  Setting,
} from "@element-plus/icons-vue";
import BotIcon from "../icons/BotIcon.vue";

export default /*@__PURE__*/_defineComponent({
  __name: 'SideNav',
  setup(__props) {

const iconComponents = {
  HomeFilled,
  User,
  Document,
  InfoFilled,
  Brush,
  Message,
  Menu,
  Close,
  BotIcon,
  Key,
  Avatar,
  Ticket,
  CreditCard,
  Guide,
  Odometer,
  List,
  CollectionTag,
  Money,
  Setting,
};
const authStore = useAuthStore();
const router = useRouter();
const isCollapse = ref(true);
const { isMobileView } = useResponsive();
// Добавляем новое состояние
const mobileMenuOpen = ref(false);
// Функция переключения мобильного меню
const toggleMobileMenu = () => {
  mobileMenuOpen.value = !mobileMenuOpen.value;
  // Блокируем прокрутку body при открытом меню
  if (mobileMenuOpen.value) {
    document.body.style.overflow = "hidden";
    isCollapse.value = false;
  } else {
    document.body.style.overflow = "";
    isCollapse.value = true;
  }
};
// Закрыть мобильное меню
const closeMobileMenu = () => {
  mobileMenuOpen.value = false;
  document.body.style.overflow = "";
};

// Модифицируем обработчик выбора пункта меню
const handleSelect = () => {
  if (isMobileView.value) closeMobileMenu();
};
// Функция для получения компонента иконки по имени
const getIconComponent = (iconName: string) => {
  return iconComponents[iconName as keyof typeof iconComponents];
};

// Функция проверки доступа по ролям
const hasAccess = (roles?: string[]) => {
  if (!roles || roles.length === 0) return true;
  return roles.some((role: string) => authStore.hasRole(role));
};

// Функция фильтрации дочерних маршрутов
const filterChildren = (children: RouteRecordRaw[] = []) => {
  return children.filter(
    (child) => !child.meta?.roles || hasAccess(child.meta.roles as string[])
  );
};

const menuRoutes = computed(() => {
  const routes = router.options.routes;

  const processRoute = (route: RouteRecordRaw) => {
    const processedRoute = { ...route };

    if (processedRoute.meta?.icon) {
      processedRoute.meta = {
        ...processedRoute.meta,
        iconComponent: getIconComponent(processedRoute.meta.icon as string),
      };
    }

    if (processedRoute.path === "/bots") {
      delete processedRoute.children;
      processedRoute.meta = {
        ...processedRoute.meta,
        hideInMenu: false,
      };
    }

    return processedRoute;
  };

  const publicRoutes = routes
    .filter((route) => {
      // Проверяем права доступа
      if (route.meta?.roles && !hasAccess(route.meta.roles as string[])) {
        return false;
      }

      // Фильтруем только нужные маршруты
      if (!route.meta?.requiresAuth || route.meta?.hideInMenu) {
        return false;
      }

      // Для вложенных маршрутов проверяем родительский путь
      if (route.path === "/bots") {
        return true;
      }

      return route.path.startsWith("/") && !route.path.includes(":");
    })
    .map(processRoute);

  return publicRoutes;
});

const handleOpen = (key: string, keyPath: string[]) => {
  console.log(key, keyPath);
};

const handleClose = (key: string, keyPath: string[]) => {
  console.log(key, keyPath);
};

return (_ctx: any,_cache: any) => {
  const _component_el_icon = _resolveComponent("el-icon")!
  const _component_el_button = _resolveComponent("el-button")!
  const _component_el_menu_item = _resolveComponent("el-menu-item")!
  const _component_el_menu_item_group = _resolveComponent("el-menu-item-group")!
  const _component_el_sub_menu = _resolveComponent("el-sub-menu")!
  const _component_el_menu = _resolveComponent("el-menu")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["side-nav-wrapper", { mobile: _unref(isMobileView) }])
  }, [
    (_unref(isMobileView))
      ? (_openBlock(), _createElementBlock("div", {
          key: 0,
          class: "mobile-toggle",
          onClick: toggleMobileMenu
        }, [
          _createVNode(_component_el_icon, { size: "24" }, {
            default: _withCtx(() => [
              (!mobileMenuOpen.value)
                ? (_openBlock(), _createBlock(_unref(Menu), { key: 0 }))
                : (_openBlock(), _createBlock(_unref(Close), { key: 1 }))
            ]),
            _: 1
          })
        ]))
      : _createCommentVNode("", true),
    _createElementVNode("div", {
      class: _normalizeClass(["side-nav-container", { 'mobile-open': mobileMenuOpen.value && _unref(isMobileView) }])
    }, [
      (!_unref(isMobileView))
        ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
            _createVNode(_component_el_button, {
              link: "",
              size: "small",
              plain: "",
              onClick: _cache[0] || (_cache[0] = ($event: any) => (isCollapse.value = !isCollapse.value))
            }, {
              default: _withCtx(() => [
                (isCollapse.value)
                  ? (_openBlock(), _createBlock(_component_el_icon, {
                      key: 0,
                      size: "18"
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_unref(Menu))
                      ]),
                      _: 1
                    }))
                  : (_openBlock(), _createBlock(_component_el_icon, {
                      key: 1,
                      size: "18"
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_unref(Close))
                      ]),
                      _: 1
                    }))
              ]),
              _: 1
            })
          ]))
        : _createCommentVNode("", true),
      _createVNode(_component_el_menu, {
        class: "side-nav",
        "default-openeds": ['1'],
        router: "",
        onSelect: handleSelect,
        collapse: isCollapse.value,
        onOpen: handleOpen,
        onClose: handleClose
      }, {
        default: _withCtx(() => [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(menuRoutes.value, (route) => {
            return (_openBlock(), _createElementBlock(_Fragment, {
              key: route.path
            }, [
              (!route.meta?.hideInMenu && !route.children)
                ? (_openBlock(), _createBlock(_component_el_menu_item, {
                    key: 0,
                    index: route.path
                  }, {
                    title: _withCtx(() => [
                      _createTextVNode(_toDisplayString(route.meta?.breadcrumb || route.name), 1)
                    ]),
                    default: _withCtx(() => [
                      (route.meta?.iconComponent)
                        ? (_openBlock(), _createBlock(_component_el_icon, { key: 0 }, {
                            default: _withCtx(() => [
                              (_openBlock(), _createBlock(_resolveDynamicComponent(route.meta.iconComponent)))
                            ]),
                            _: 2
                          }, 1024))
                        : _createCommentVNode("", true)
                    ]),
                    _: 2
                  }, 1032, ["index"]))
                : (route.meta?.group)
                  ? (_openBlock(), _createBlock(_component_el_menu_item_group, {
                      key: 1,
                      title: route.meta.group
                    }, {
                      default: _withCtx(() => [
                        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(filterChildren(route.children), (child) => {
                          return (_openBlock(), _createBlock(_component_el_menu_item, {
                            key: child.path,
                            index: child.path
                          }, {
                            title: _withCtx(() => [
                              _createTextVNode(_toDisplayString(child.meta?.breadcrumb || child.name), 1)
                            ]),
                            default: _withCtx(() => [
                              (child.meta?.iconComponent)
                                ? (_openBlock(), _createBlock(_component_el_icon, { key: 0 }, {
                                    default: _withCtx(() => [
                                      (_openBlock(), _createBlock(_resolveDynamicComponent(child.meta.iconComponent)))
                                    ]),
                                    _: 2
                                  }, 1024))
                                : _createCommentVNode("", true)
                            ]),
                            _: 2
                          }, 1032, ["index"]))
                        }), 128))
                      ]),
                      _: 2
                    }, 1032, ["title"]))
                  : (route.children)
                    ? (_openBlock(), _createBlock(_component_el_sub_menu, {
                        key: 2,
                        index: route.path
                      }, {
                        title: _withCtx(() => [
                          (route.meta?.iconComponent)
                            ? (_openBlock(), _createBlock(_component_el_icon, { key: 0 }, {
                                default: _withCtx(() => [
                                  (_openBlock(), _createBlock(_resolveDynamicComponent(route.meta.iconComponent)))
                                ]),
                                _: 2
                              }, 1024))
                            : _createCommentVNode("", true),
                          _createElementVNode("span", null, _toDisplayString(route.meta?.breadcrumb || route.name), 1)
                        ]),
                        default: _withCtx(() => [
                          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(filterChildren(route.children), (child) => {
                            return (_openBlock(), _createBlock(_component_el_menu_item, {
                              key: child.path,
                              index: child.path
                            }, {
                              title: _withCtx(() => [
                                _createTextVNode(_toDisplayString(child.meta?.breadcrumb || child.name), 1)
                              ]),
                              default: _withCtx(() => [
                                (child.meta?.iconComponent)
                                  ? (_openBlock(), _createBlock(_component_el_icon, { key: 0 }, {
                                      default: _withCtx(() => [
                                        (_openBlock(), _createBlock(_resolveDynamicComponent(child.meta.iconComponent)))
                                      ]),
                                      _: 2
                                    }, 1024))
                                  : _createCommentVNode("", true)
                              ]),
                              _: 2
                            }, 1032, ["index"]))
                          }), 128))
                        ]),
                        _: 2
                      }, 1032, ["index"]))
                    : _createCommentVNode("", true)
            ], 64))
          }), 128))
        ]),
        _: 1
      }, 8, ["collapse"])
    ], 2),
    (_unref(isMobileView))
      ? (_openBlock(), _createElementBlock("div", {
          key: 1,
          class: _normalizeClass(["mobile-overlay", { active: mobileMenuOpen.value }]),
          onClick: closeMobileMenu
        }, null, 2))
      : _createCommentVNode("", true)
  ], 2))
}
}

})