import { useDark, useToggle } from "@vueuse/core";

export function useTheme() {
  // Используем useDark из VueUse
  const isDark = useDark({
    selector: "html",
    attribute: "class",
    valueDark: "dark",
    valueLight: "light",
    initialValue: "dark", // Устанавливаем темную тему по умолчанию
    storage: localStorage, // Сохраняем выбор в localStorage
  });

  const toggleDark = useToggle(isDark);

  return {
    isDark,
    toggleDark,
  };
}
