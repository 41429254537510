import type { RouteLocationNormalized, RouteRecordRaw } from "vue-router";
import { createBotBreadcrumb } from "@/router/utils/breadcrumb";
import { lazyLoad } from "@/router/utils/lazyLoad";
import { ROUTE_NAMES } from "@/constants/routes";
import { useBotsStore } from "@/stores/modules/bots";

export const botRoutes: RouteRecordRaw[] = [
  {
    path: "/bots",
    component: () => import("@/layouts/BotLayout.vue"),
    redirect: "/bots",
    meta: {
      requiresAuth: true,
      title: "Боты",
      breadcrumb: "Боты",
      icon: "BotIcon",
      hideInMenu: false,
    },
    children: [
      {
        path: "",
        name: ROUTE_NAMES.BOTS,
        component: lazyLoad("bot/PageList"),
        meta: {
          requiresAuth: true,
          title: "Боты",
          breadcrumb: "Боты",
          hideInMenu: true,
        },
      },
      {
        path: ":id",
        component: lazyLoad("bot/BotContainer"),
        props: true, // Передаем id как проп
        children: [
          {
            path: "", // /bots/:id
            name: ROUTE_NAMES.BOT_ITEM,
            component: lazyLoad("bot/PageItem"),
            meta: {
              requiresAuth: true,
              title: "Информация о боте",
              breadcrumb: createBotBreadcrumb("Детали бота"),
              hideInMenu: true,
            },
          },
          // Медиа
          {
            path: "media", // /bots/:id/media
            name: ROUTE_NAMES.MEDIA,
            component: lazyLoad("bot/PageMedia"),
            meta: {
              title: "Файлы бота",
              breadcrumb: createBotBreadcrumb("Файлы"),
            },
          },
          // Настройки
          {
            path: "settings", // /bots/:id/settings
            name: ROUTE_NAMES.SETTINGS,
            component: lazyLoad("bot/PageSettings"),
            meta: {
              title: "Настройки бота",
              breadcrumb: createBotBreadcrumb("Настройки"),
            },
          },
          // Общая аналитика рассылок
          {
            path: "broadcasts",
            name: ROUTE_NAMES.BROADCAST_BOT,
            component: lazyLoad("broadcast/BroadcastBot"),
            meta: {
              title: "Рассылки бота",
              breadcrumb: createBotBreadcrumb("Рассылки"),
            },
          },
          // Контент
          {
            path: "contents",
            props: true, // Передаем id как проп
            children: [
              // Список контента
              {
                path: "", // /contents
                name: ROUTE_NAMES.CONTENTS,
                component: lazyLoad("bot/contents/PageList"),
                meta: {
                  title: "Список контента",
                  breadcrumb: (route: RouteLocationNormalized) => {
                    const store = useBotsStore();
                    const bot = store.bot;
                    return [
                      {
                        title:
                          bot?.first_name ||
                          bot?.userId ||
                          `Бот #${route.params.id}`,
                        path: `/bots/${route.params.id}`,
                      },
                      {
                        title: "Контент",
                        path: route.path,
                      },
                    ];
                  },
                },
              },
              // Контент
              {
                path: ":contentId",
                props: true, // Передаем id как проп
                children: [
                  // Контент
                  {
                    path: "",
                    name: ROUTE_NAMES.CONTENT_ITEM,
                    component: lazyLoad("bot/contents/PageItem"),
                    meta: {
                      title: "Пост бота",
                      breadcrumb: (route: RouteLocationNormalized) => {
                        const store = useBotsStore();
                        const bot = store.bot;
                        return [
                          {
                            title:
                              bot?.first_name ||
                              bot?.userId ||
                              `Бот #${route.params.id}`,
                            path: `/bots/${route.params.id}`,
                          },
                          {
                            title: "Контент",
                            path: `/bots/${route.params.id}/contents`,
                          },
                          {
                            title: `Пост #${route.params.contentId}`,
                          },
                        ];
                      },
                    },
                  },
                  // Аналитика по рассылке
                  {
                    path: "broadcasts",
                    name: ROUTE_NAMES.BROADCAST_CONTENT,
                    component: lazyLoad("broadcast/BroadcastContent"),
                    meta: {
                      title: "Рассылка по контенту",
                      breadcrumb: (route: RouteLocationNormalized) => {
                        const store = useBotsStore();
                        const bot = store.bot;
                        return [
                          {
                            title:
                              bot?.first_name ||
                              bot?.userId ||
                              `Бот #${route.params.id}`,
                            path: `/bots/${route.params.id}`,
                          },
                          {
                            title: "Контент",
                            path: `/bots/${route.params.id}/contents`,
                          },
                          {
                            title: `Пост #${route.params.contentId}`,
                            path: `/bots/${route.params.id}/contents/${route.params.contentId}`,
                          },
                          {
                            title: "Рассылка",
                          },
                        ];
                      },
                    },
                  },
                ],
              },
            ],
          },
          // Сообщения
          {
            path: "messages",
            name: ROUTE_NAMES.MESSAGES,
            component: lazyLoad("bot/PageMessages"),
            meta: {
              title: "Сообщения бота",
              breadcrumb: createBotBreadcrumb("Сообщения"),
            },
          },
          // Воронки
          {
            path: "funnels",
            props: true, // Передаем id как проп
            children: [
              {
                path: "",
                name: ROUTE_NAMES.FUNNELS,
                component: lazyLoad("bot/funnels/BotFunnels"),
                meta: {
                  title: "Сценарии бота",
                  breadcrumb: createBotBreadcrumb("Сценарии"),
                },
              },
              {
                path: ":funnelId",
                name: ROUTE_NAMES.FUNNEL_STATS,
                component: lazyLoad("bot/funnels/PageStats"),
                meta: {
                  title: "Статистика сценария",
                  breadcrumb: createBotBreadcrumb("Статистика сценария"),
                },
              },
            ],
          },
          // Редактор воронки
          {
            path: "flow/:funnelId",
            name: ROUTE_NAMES.FUNNEL_EDITOR,
            component: lazyLoad("bot/funnels/VueFlow"),
            meta: {
              title: "Редактор функционала воронки",
              breadcrumb: createBotBreadcrumb("Редактор воронки"),
            },
          },
          // Команды
          {
            path: "commands",
            name: ROUTE_NAMES.COMMANDS,
            component: lazyLoad("bot/funnels/BotCommands"),
            meta: {
              title: "Команды бота",
              breadcrumb: createBotBreadcrumb("Команды"),
            },
          },
          // Задачи
          {
            path: "tasks",
            name: ROUTE_NAMES.TASK_LIST,
            component: lazyLoad("bot/tasks/PageList"),
            meta: {
              title: "Задачи бота",
              breadcrumb: createBotBreadcrumb("Задачи"),
            },
          },
          // Подписчики
          {
            path: "subscribers",
            name: ROUTE_NAMES.SUBSCRIBERS,
            component: lazyLoad("bot/subscribers/PageSubscribers"),
            meta: {
              requiresAuth: true,
              title: "Подписчики бота",
              breadcrumb: createBotBreadcrumb("Подписчики"),
            },
          },
          // Тарифы
          {
            path: "offers",
            name: ROUTE_NAMES.OFFERS,
            component: lazyLoad("bot/offers/PageOffers"),
            meta: {
              requiresAuth: true,
              title: "Тарифы",
              breadcrumb: createBotBreadcrumb("Тарифы"),
            },
          },
          // История платежей
          {
            path: "payments",
            name: ROUTE_NAMES.PAYMENTS,
            component: lazyLoad("bot/payments/PageBotPayments"),
            meta: {
              requiresAuth: true,
              title: "История платежей",
              breadcrumb: createBotBreadcrumb("История платежей"),
            },
          },
          // Настройки платежей
          {
            path: "yookassa-settings",
            name: ROUTE_NAMES.YOOKASSA_SETTINGS,
            component: lazyLoad("bot/payments/PageYooKassaSettings"),
            meta: {
              requiresAuth: true,
              title: "Настройки платежей",
              breadcrumb: createBotBreadcrumb("Настройки платежей"),
            },
          },
        ],
      },
    ],
  },
];
