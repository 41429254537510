import type { RouteRecordRaw } from "vue-router";
import { ROUTE_NAMES } from "@/constants/routes";
import { lazyLoad } from "../../utils/lazyLoad";
export const adminRoutes: RouteRecordRaw[] = [
  {
    path: "/admin",
    meta: {
      requiresAuth: true,
      title: "Админка",
      breadcrumb: "Админка",
      icon: "Odometer",
      hideInMenu: false,
      roles: ["admin"],
      accessMode: "all",
    },
    children: [
      {
        path: "/admin",
        name: ROUTE_NAMES.ADMIN,
        component: lazyLoad("admin/Admin"),
        meta: {
          requiresAuth: true,
          title: "Админка",
          breadcrumb: "Админка",
          icon: "Odometer",
          hideInMenu: true,
          roles: ["admin"],
          accessMode: "all",
        },
      },
      {
        path: "/admin/users",
        name: ROUTE_NAMES.USERS,
        component: lazyLoad("users/UsersList"),
        meta: {
          requiresAuth: true,
          title: "Управление пользователями",
          breadcrumb: "Пользователи",
          icon: "User",
          hideInMenu: false,
          roles: ["admin", "manager"],
          permissions: ["users.manage"],
          accessMode: "all", // any - Достаточно ОДНОЙ роли или разрешения, "all" - Нужны ОБЕ роли и разрешения
        },
      },
      {
        path: "/admin/permissions",
        name: ROUTE_NAMES.PERMISSIONS,
        component: lazyLoad("admin/PermissionsManager"),
        meta: {
          requiresAuth: true,
          title: "Разрешения",
          breadcrumb: "Разрешения",
          icon: "Key",
          hideInMenu: false,
          roles: ["admin"],
        },
      },
      {
        path: "/admin/plan",
        name: ROUTE_NAMES.PLAN,
        component: lazyLoad("admin/PlanSubscriber"),
        meta: {
          requiresAuth: true,
          title: "Подписки",
          breadcrumb: "Подписки",
          icon: "Ticket",
          hideInMenu: false,
          roles: ["admin"],
          accessMode: "any",
        },
      },
      {
        path: "/admin/theme",
        name: ROUTE_NAMES.THEME,
        component: lazyLoad("PageElements"),
        meta: {
          requiresAuth: true,
          title: "Тема",
          breadcrumb: "Тема",
          icon: "Brush",
          hideInMenu: false,
          roles: ["admin", "manager"],
          accessMode: "any",
        },
      },
      {
        path: "/admin/bots",
        name: ROUTE_NAMES.ADMIN_BOTS,
        component: lazyLoad("admin/AdminBot"),
        meta: {
          requiresAuth: true,
          title: "Все боты системы",
          breadcrumb: "Боты",
          icon: "Robot",
          hideInMenu: false,
          roles: ["admin"],
          accessMode: "any",
        },
      },
      {
        path: "/admin/redis",
        name: ROUTE_NAMES.REDIS,
        component: lazyLoad("redis/RedisManager"),
        meta: {
          requiresAuth: true,
          title: "Управление Redis",
          breadcrumb: "Redis",
          icon: "Odometer",
          hideInMenu: false,
          roles: ["admin"],
          accessMode: "any",
        },
      },
    ],
  },
  {
    path: "/admin/users/:id/permissions",
    name: ROUTE_NAMES.USER_PERMISSIONS,
    props: true,
    component: lazyLoad("users/UserPermission"),
    meta: {
      requiresAuth: true,
      title: "Разрешения пользователя",
      breadcrumb: "Разрешения пользователя",
      hideInMenu: true,
      roles: ["admin"],
    },
  },
];
