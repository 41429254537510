import type { DirectiveBinding } from "vue";
import { useAuthStore } from "@/stores/modules/auth";

export interface RoleDirectiveOptions {
  role?: string | string[]; // Роль или массив ролей
  permission?: string | string[]; // Разрешение или массив разрешений
  mode?: "all" | "any"; // Режим проверки: все или любой
}

export const role = {
  mounted(
    el: HTMLElement,
    binding: DirectiveBinding<RoleDirectiveOptions | string | string[]>
  ) {
    updateVisibility(el, binding);
  },

  updated(
    el: HTMLElement,
    binding: DirectiveBinding<RoleDirectiveOptions | string | string[]>
  ) {
    updateVisibility(el, binding);
  },
};

function updateVisibility(
  el: HTMLElement,
  binding: DirectiveBinding<RoleDirectiveOptions | string | string[]>
) {
  const auth = useAuthStore();
  const value = binding.value;

  // Если пользователь не авторизован
  if (!auth.user) {
    el.style.display = "none";
    return;
  }

  let hasAccess = false;

  // Если передан объект с опциями
  if (typeof value === "object" && !Array.isArray(value)) {
    const options = value as RoleDirectiveOptions;
    const mode = options.mode || "any";

    if (options.role) {
      const roles = Array.isArray(options.role) ? options.role : [options.role];
      hasAccess =
        mode === "all"
          ? roles.every((role) => auth.hasRole(role))
          : roles.some((role) => auth.hasRole(role));
    }

    if (options.permission) {
      const permissions = Array.isArray(options.permission)
        ? options.permission
        : [options.permission];

      hasAccess =
        mode === "all"
          ? permissions.every((perm) => auth.hasPermission(perm))
          : permissions.some((perm) => auth.hasPermission(perm));
    }
  } else {
    // Если передана строка или массив - проверяем как роли
    const roles = Array.isArray(value) ? value : [value as string];
    hasAccess = roles.some((role) => auth.hasRole(role));
  }

  el.style.display = hasAccess ? "" : "none";
}
